import { AxiosPromise } from 'axios';
import axios from '@/api/service';

const SITE_NAME = process.env.VUE_APP_OM27_SP_SITE_NAME;
let EVIDENCE_FILES_FOLDER = process.env.VUE_APP_OM27_EVIDENCE_FILES_FALLBACK_FOLDER;
let foldersByEnv: any = {};
try {
  foldersByEnv = JSON.parse(process.env.VUE_APP_OM27_EVIDENCE_FILES_MAIN_FOLDER);
} catch (e) {
  console.error(e);
}
if (['DEV', 'UAT', 'PROD'].includes(process.env.VUE_APP_BUILD_TARGET) && process.env.VUE_APP_BUILD_TARGET in foldersByEnv) {
  EVIDENCE_FILES_FOLDER = foldersByEnv[process.env.VUE_APP_BUILD_TARGET];
}

const sitePath = `/sites/${SITE_NAME}`;
const evidenceFolderPath = `/sites/${SITE_NAME}/${EVIDENCE_FILES_FOLDER}`;
const api = `${sitePath}/_api`;

const SharepoinUrls = {
  GetFormDigestValue: `${api}/contextinfo`,
  GetFolders: `${api}/web/GetFolderByServerRelativeUrl('${evidenceFolderPath}%path%')/Folders`,
  CreateFolder: `${api}/web/Folders/add('${evidenceFolderPath}%path%')`,
  UploadFile: `${api}/web/GetFolderByServerRelativeUrl('${evidenceFolderPath}%path%')/Files/add(url='%file%',overwrite=false)`,
  StartUpload: `${api}/web/GetFileByServerRelativeUrl('${evidenceFolderPath}%path%/%file%')/startUpload(uploadId=guid'%uploadId%')`,
  ContinueUpload: `${api}/web/GetFileByServerRelativeUrl('${evidenceFolderPath}%path%/%file%')/continueUpload(uploadId=guid'%uploadId%',fileOffset=%offset%)`,
  FinishUpload: `${api}/web/GetFileByServerRelativeUrl('${evidenceFolderPath}%path%/%file%')/finishUpload(uploadId=guid'%uploadId%',fileOffset=%offset%)`,
};

function buildUrl(endpoint: string, replaceParams?: any): string {
  let url = `${process.env.VUE_APP_OM27_SP_SITE_BASE_URL}${endpoint}`;
  if (replaceParams) {
    Object.entries(replaceParams).forEach(([key, value]) => {
      url = url.replace(`%${key}%`, value as string);
    });
  }
  return url;
}

function removeSpecialChars(str: string) {
  return str.replace(/[^A-Z a-z0-9._-]/g, '');
}

export function getFilePreviewUrl(fileName: string, filePath: string): string {
  const url = `${process.env.VUE_APP_OM27_SP_SITE_BASE_URL}${sitePath}/${EVIDENCE_FILES_FOLDER}/Forms/AllItems.aspx`;
  const parentQueryParam = `${sitePath}/${EVIDENCE_FILES_FOLDER}${filePath}`;
  const idQueryParam = `${parentQueryParam}/${fileName}`;
  return `${url}?id=${encodeURIComponent(idQueryParam)}&parent=${encodeURIComponent(parentQueryParam)}`;
}

export function getFormDigestValue(token: string): AxiosPromise<any> {
  const url = buildUrl(SharepoinUrls.GetFormDigestValue);
  return axios.post(url, null, {
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json;odata=nometadata',
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  });
}

export function getFolders(token: string, path: string): AxiosPromise<any> {
  const url = buildUrl(SharepoinUrls.GetFolders, { path });
  return axios.get(url, {
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json;odata=nometadata',
    },
  });
}

export function createFolder(
  token: string,
  digest: string,
  path: string
): AxiosPromise<any> {
  const url = buildUrl(SharepoinUrls.CreateFolder, { path });
  return axios.post(url, null, {
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json;odata=nometadata',
      'X-RequestDigest': digest,
      'Content-Type': 'application/json; odata=verbose',
    },
  });
}

export function addFile(
  token: string,
  digest: string,
  path: string,
  fileName: string,
  file: ArrayBuffer,
): AxiosPromise<any> {
  const fileNameToUpload = removeSpecialChars(fileName);
  const url = buildUrl(SharepoinUrls.UploadFile, { path, file: fileNameToUpload });
  return axios.post(url, file, {
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json;odata=nometadata',
      'X-RequestDigest': digest,
    },
  });
}

function upload(
  urlPattern: string,
  token: string,
  digest: string,
  path: string,
  fileName: string,
  chunk: ArrayBuffer,
  uploadId: string,
  offset?: number,
): AxiosPromise<any> {
  const url = buildUrl(urlPattern, {
    path,
    file: fileName,
    uploadId,
    offset,
  });
  return axios.post(url, chunk, {
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json;odata=verbose',
      'X-RequestDigest': digest,
      'X-FORMS_BASED_AUTH_ACCEPTED': 'f',
    },
  });
}

export const startUpload = upload.bind(null, SharepoinUrls.StartUpload);
export const continueUpload = upload.bind(null, SharepoinUrls.ContinueUpload);
export const finishUpload = upload.bind(null, SharepoinUrls.FinishUpload);
