
import { Component, Prop, Vue } from 'vue-property-decorator';
import { LogData, SignOffFilters } from '@/features/om27/types/om27.types';
import { DateRenderOptions, getLogDateRenderer } from '@/features/om27/utils/datetime';
import { MenuOption } from '@/components/gsk-components/menu/menu.types';
import {
  Filter,
  FilterSettings,
  SignOffColumnData,
  SortParams,
} from '@/features/om27/types/signoff.types';
import IconPopoverList from '@/components/gsk-components/menu/IconPopoverList.vue';
import { RpaOpsModule } from '@/features/om27/store/om27.module';

@Component({
  components: {
    IconPopoverList,
  },
})
export default class SignOffGrid extends Vue {
  @Prop({ required: true, type: Array }) logData!: LogData[];
  @Prop({ required: true, type: String }) timeZone!: string;
  @Prop({ required: true, type: Object }) filters!: SignOffFilters;
  @Prop({ required: true, type: Object }) activeFilters!: SignOffFilters;
  @Prop({ type: Boolean }) dense!: boolean;

  prefs = {
    max: false,
    pinDate: false,
    pinFile: false,
  };

  get eventCustomOption() {
    return {
      headerCellEvents: (tab: { column: SignOffColumnData; rowIndx: number }) => {
        return {
          dblclick: () => {
            if (tab.column.field === 'nonNullDateField') {
              this.prefs.pinDate = !this.prefs.pinDate;
            }
            if (tab.column.field === 'fileName') {
              this.prefs.pinFile = !this.prefs.pinFile;
            }
          },
        };
      },
    };
  }

  hiddenCols: Record<keyof LogData, boolean> = {
    botLogId: false,
    botId: false,
    fileName: false,
    machineNameShort: false,
    botRunnerUserId: false,
    startDate: false,
    startMsg: false,
    errorDate: false,
    finishDate: false,
    finishMsg: false,
    errorMsg: false,
    confirmedStartDate: false,
    confirmedStartMsg: false,
    trustedFinishedDate: false,
    trustedFinishedMsg: false,
    nonNullDateField: false,
  };
  toggleCol(col: keyof LogData): void {
    this.hiddenCols[col] = !this.hiddenCols[col];
  }
  get menuOptions(): MenuOption[] {
    return [
      {
        key: 'fileName',
        text: 'Toggle task name',
        handler: () => {
          this.toggleCol('fileName');
        },
      },
      {
        key: 'machineNameShort',
        text: 'Toggle server',
        handler: () => {
          this.toggleCol('machineNameShort');
        },
      },
      {
        key: 'enlarge',
        text: this.prefs.max ? 'Reduce table' : 'Enlarge table',
        handler: () => {
          this.prefs.max = !this.prefs.max;
        },
      },
    ];
  }

  get scrollWidth() {
    return 'max(1156px, 100vw - 544px)';
  }

  get rows() {
    return this.logData;
  }

  handleFilterUpdate(key: 'machineNameShort' | 'fileName', filters: Filter[]) {
    const sel = filters.filter(f => f.selected).map(f => f.value);
    this.$emit('update:activeFilters', { ...this.activeFilters, [key]: sel });
  }

  makeFilter(vals: string[]): Filter[] {
    return vals.map(val => ({ value: val, label: val, selected: false }));
  }

  getFilter(key: 'machineNameShort' | 'fileName'): FilterSettings {
    return {
      isMultiple: true,
      filterList: this.makeFilter(this.filters[key]),
      filterConfirm: filterList => {
        this.handleFilterUpdate(key, filterList);
      },
      filterReset: () => {
        this.handleFilterUpdate(key, []);
      },
      maxHeight: 120,
    };
  }

  get columns(): SignOffColumnData[] {
    const renderName: SignOffColumnData['renderBodyCell'] = ({ row }, h) => {
      return <div style="word-break: break-all;">{row.fileName}</div>;
    };
    const width = 90;
    const iconWidth = 110;

    const cols: SignOffColumnData[] = [
      {
        field: 'nonNullDateField',
        title: 'Date',
        renderBodyCell: this.getDateRenderer('nonNullDateField', '', 'date'),
        fixed: this.prefs.pinDate ? 'right' : undefined,
        width: width + 80,
      },
      {
        field: 'startDate',
        title: 'Start',
        renderBodyCell: this.getDateRenderer('startDate', 'startMsg'),
        width: width,
      },
      {
        field: 'confirmedStartDate',
        title: 'Confirmed Start',
        renderBodyCell: this.getDateRenderer('confirmedStartDate', 'confirmedStartMsg'),
        width: iconWidth,
      },
      {
        field: 'errorDate',
        title: 'Error',
        renderBodyCell: this.getDateRenderer('errorDate', 'errorMsg'),
        width: iconWidth,
      },
      {
        field: 'trustedFinishedDate',
        title: 'Successful',
        renderBodyCell: this.getDateRenderer('trustedFinishedDate', 'trustedFinishedMsg'),
        width: iconWidth,
      },
      {
        field: 'finishDate',
        title: 'Finish',
        renderBodyCell: this.getDateRenderer('finishDate', 'finishMsg'),
        width: width,
      },
      {
        field: 'botRunnerUserId',
        title: 'Runner',
        width: width
      },
      {
        field: 'fileName',
        title: 'Task Name',
        width: 245,
        renderBodyCell: renderName,
        fixed: this.prefs.pinFile ? 'left' : undefined,
        sortBy: '',
        filter: this.filters.fileName.length > 1 ? this.getFilter('fileName') : undefined,
      },
      {
        field: 'machineNameShort',
        title: 'Server',
        width: 140,
        filter:
          this.filters.machineNameShort.length > 1 ? this.getFilter('machineNameShort') : undefined,
      },
    ];

    return cols
      .filter(col => !this.hiddenCols[col.field])
      .map(col => {
        return { sortBy: '', align: 'left', key: col.field, ...col };
      });
  }

  get sortOptions() {
    return {
      multipleSort: false,
      sortChange: (params: SortParams) => {
        this.sortChange(params);
      },
    };
  }

  sortChange(params: SortParams): void {
    const fields: string[] = [];
    const dirs: ('asc' | 'desc' | '')[] = [];
    Object.entries(params)
      .filter(entry => entry[1] !== '')
      .forEach(entry => {
        fields.push(entry[0]);
        dirs.push(entry[1]);
      });
    this.$emit('sort', [fields, dirs]);
  }

  getDateRenderer(
    field: keyof LogData,
    info: keyof LogData | '' = '',
    format: 'date' | 'time' = 'time',
  ): SignOffColumnData['renderBodyCell'] {
    let opts: DateRenderOptions = {
      timeOnly: format === 'time',
      dateOnly: format === 'date',
      timezoneAbbrv: RpaOpsModule.userPreferences.timezone_short || undefined,
    };

    return getLogDateRenderer(field, this.timeZone, info, opts);
  }
}
